<template>
  <div class="wrap">
    <b-tabs
      v-model="step"
      position="is-centered"
      expanded
    >
      <b-tab-item
        :label="$t('general')"
        value="1"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          class="mt-2"
          tag="div"
        >
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(saveGeneral)"
          >
            <div class="columns is-multiline is-mobile">
              <!-- <div class="column is-6-tablet is-12-mobile">
                <ValidationProvider
                  v-slot="{ errors }"
                  tag="div"
                  vid="parent_id"
                  name="parent_id"
                  rules="required"
                >
                  <b-field
                    :label="$tc('tree', 1)"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-taginput
                      ref="taginput"
                      v-model="myCategory.parent_id"
                      rules="required"
                      :data="mappedCategories"
                      :placeholder="
                        myCategory.parent_id == 0 ? $t('choose') : ''
                      "
                      :disabled="disableInfos"
                      :readonly="
                        myCategory.parent_id && myCategory.parent_id.length == 1
                      "
                      autocomplete
                      open-on-focus
                      ellipsis
                      aria-close-label="Delete this tag"
                      :before-adding="beforeAddParent"
                      @add="changeParent"
                      @typing="filterCats"
                    >
                      <span
                        slot-scope="props"
                        :class="
                          `${props.option.cls}${
                            props.option.disabled
                              ? ' has-text-grey-lighter'
                              : ''
                          }`
                        "
                        >{{ props.option.label }}</span
                      >
                      <template slot="selected" slot-scope="props">
                        <b-tag
                          v-for="(tag, i) in props.tags"
                          :key="i"
                          type="is-default"
                          :tabstop="false"
                          attached
                          >{{ tag.label }}</b-tag
                        >
                      </template>
                    </b-taginput>
                  </b-field>
                </ValidationProvider>
              </div> -->

              <div class="column is-6-tablet is-12-mobile">
                <b-select-validation
                  v-model="myCategory.parent_id"
                  :expanded="true"
                  :label="$tc('tree', 1)"
                  rules="required"
                  vid="parent_id"
                  :disabled="disableInfos"
                  item-text="label"
                  field="id"
                  type="custom-category"
                  has-null
                >
                  <template>
                    <option
                      v-for="opt in mappedCategories"
                      :key="opt.id"
                      :value="opt.id"
                    >
                      {{ opt.level == 2 ? " -- " + opt.label : opt.label }} {{ !opt.active ? " [" + $t("removed") + "]" : "" }}
                    </option>                    
                  </template>
                </b-select-validation>
              </div>

              <div class="column is-6-tablet is-12-mobile">
                <b-input-validation
                  v-model="myCategory.google_category_id"
                  :disabled="disableInfos"
                  rules="required"
                  :label="$t('google_category_id')"
                  vid="google_category_id"
                  :placeholder="placeholders.number['4']"
                  input-type="number"
                />
              </div>

              <div class="column is-6">
                <b-select-validation
                  v-model="myCategory.attribute"
                  :disabled="disableInfos"
                  vid="attributes"
                  :label="$tc('attribute', 1) + ' (' + $t('optional') + ')'"
                >
                  <template>
                    <option
                      v-for="opt in attributes"
                      :key="opt.id"
                      :value="opt.id"
                    >
                      {{ opt.label }}
                    </option>
                  </template>
                </b-select-validation>
              </div>

              <div class="column is-3-tablet is-6-mobile">
                <b-input-validation
                  v-model="myCategory.rank"
                  :disabled="disableInfos"
                  rules="required"
                  vid="rank"
                  :label="$t('rank')"
                  input-type="number"
                  :placeholder="placeholders.number['1']"
                />
              </div>

              <div class="column is-3-tablet is-12-mobile">
                <b-switch
                  v-model="myCategory.noindex"
                  class="mt-2"
                  :disabled="disableInfos"
                >
                  {{ $t("noindex") }}
                </b-switch>
              </div>

              <div class="column">
                <b-button
                  type="is-primary"
                  :disabled="save_general_loading || disableInfos"
                  :loading="save_general_loading"
                  tag="input"
                  native-type="submit"
                  :value="$t('save')"
                >
                  {{ $t("save") }}
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </b-tab-item>
      <b-tab-item
        :disabled="
          !(
            action === 'read' ||
            action === 'update' ||
            categoryId !== undefined
          ) || !$can('read', 'catalog.categories.seo')
        "
        :label="$t('seo')"
        value="2"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          class="mt-2"
          tag="div"
        >
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(saveSeo)"
          >
            <b-tabs
              type="is-boxed"
              class="mb-0 paddingless"
            >
              <b-tab-item
                v-for="(site, i) in sites"
                :key="'tab' + site.id"
                :value="`${site.id}`"
              >
                <template slot="header">
                  <img
                    :src="'/img/sites/' + site.id + '.svg'"
                    width="20"
                    height="20"
                    alt
                    class="mr-2"
                  >
                  {{ site.label }}
                </template>
                <b-tabs
                  :class="$device.mobile ? 'paddingless' : 'paddingless-right'"
                  :vertical="!$device.mobile"
                >
                  <b-tab-item
                    v-for="(lang, j) in site.languages"
                    :key="'lang-' + lang.iso"
                    header-class="mb-4"
                  >
                    <template slot="header">
                      <img
                        :src="'/img/langs/' + lang.iso + '.svg'"
                        width="18"
                        height="18"
                        class="mr-2"
                      >
                      {{ lang.label }}
                    </template>
                    <div style="max-width: fit-content">
                      <div class="columns is-multiline is-mobile ml-2">
                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['name']"
                            :placeholder="placeholders.category.name"
                            :label="$tc('category', 1)"
                            maxlength="50"
                            counter="50"
                            :vid="'name' + site.id + lang.iso"
                            :disabled="disableLangs"
                            @blur="
                              validSeoName(
                                $event,
                                i,
                                site.id,
                                j,
                                lang.iso,
                                false
                              )
                            "
                          />
                        </div>

                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['permalink']"
                            :placeholder="placeholders.category.permalink"
                            :disabled="
                              (action != 'create' &&
                                sites[i]['languages'][j]['permalink'] &&
                                !permalinkChangedFromInput.includes(
                                  `${i}_${j}`
                                )) ||
                                disableLangs
                            "
                            :label="$t('permalink')"
                            :rules="getPermalinkValidation(site.id, lang.iso)"
                            validation-mode="lazy"
                            maxlength="50"
                            counter="50"
                            :vid="'permalink' + site.id + lang.iso"
                          />
                        </div>

                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['h1']"
                            :placeholder="placeholders.category.h1"
                            :label="'h1' + ' (' + $t('optional') + ')'"
                            maxlength="80"
                            counter="80"
                            :vid="'h1' + site.id + lang.iso"
                            :disabled="disableLangs"
                          />
                        </div>

                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['meta_title']"
                            :placeholder="placeholders.category.meta_title"
                            :label="
                              $t('meta_title') + ' (' + $t('optional') + ')'
                            "
                            maxlength="90"
                            counter="90"
                            :vid="'meta_title' + site.id + lang.iso"
                            :disabled="disableLangs"
                          />
                        </div>

                        <div class="column is-12">
                          <b-input-validation
                            v-model="
                              sites[i]['languages'][j]['meta_description']
                            "
                            :placeholder="
                              placeholders.category.meta_description
                            "
                            input-type="textarea"
                            :vid="'meta_description' + site.id + lang.iso"
                            :label="
                              $t('meta_description') +
                                ' (' +
                                $t('optional') +
                                ')'
                            "
                            rows="2"
                            auto-grow
                            :disabled="disableLangs"
                            maxlength="300"
                            counter="300"
                            :suffix="true"
                          >
                            <b-dropdown
                              slot="suffix"
                              position="is-bottom-left"
                              aria-role="list"
                            >
                              <template #trigger="{ active }">
                                <b-button
                                  :label="$tc('variable', 2)"
                                  :icon-right="
                                    active ? 'chevron-up' : 'chevron-down'
                                  "
                                />
                              </template>

                              <b-dropdown-item
                                v-for="v in copyVariables.list"
                                :key="v.label"
                                aria-role="listitem"
                                @click="$root.copytoClipboard(v.value)"
                              >
                                {{ v.label }}
                              </b-dropdown-item>
                            </b-dropdown>
                          </b-input-validation>
                        </div>

                        <div class="column is-12">
                          <b-input-validation
                            v-model="
                              sites[i]['languages'][j]['description_short']
                            "
                            :placeholder="
                              placeholders.category.description_short
                            "
                            input-type="textarea"
                            :vid="'description_short' + site.id + lang.iso"
                            :label="
                              $t('description_short') +
                                ' (' +
                                $t('optional') +
                                ')'
                            "
                            rows="2"
                            auto-grow
                            :disabled="disableLangs"
                            maxlength="512"
                            counter="512"
                          />
                        </div>

                        <div class="column is-12 pb-0">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['description']"
                            :placeholder="placeholders.category.description"
                            input-type="textarea"
                            :vid="'description' + site.id + lang.iso"
                            :disabled="disableLangs"
                            :label="
                              $t('description') + ' (' + $t('optional') + ')'
                            "
                            rows="10"
                            auto-grow
                          />
                        </div>
                      </div>
                    </div>
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
            </b-tabs>
            <b-button
              type="is-primary"
              :disabled="save_seo_loading || disableLangs"
              :loading="save_seo_loading"
              tag="input"
              native-type="submit"
              :value="$t('save')"
            >
              {{ $t("save") }}
            </b-button>
          </form>
        </ValidationObserver>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
// import { ValidationProvider } from "vee-validate";
export default {
  // components: {
  //   ValidationProvider
  // },
  props: {
    category: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    level: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      mappedCategories: [],
      save_general_loading: false,
      myCategory: null,
      step: "1",
      disableInfos: false,
      categoryId: this.category.id,
      sitesData: this.sites,
      attributes: [
        { id: "color", label: this.$i18n.tc("color", 1) },
        { id: "flavor", label: this.$i18n.tc("flavor", 1) }
      ],
      // Seo
      save_seo_loading: false,
      disableLangs: false,
      permalinkChangedFromInput: []
    };
  },
  computed: {
    method() {
      if (!this.categoryId) return "post";
      else return "put";
    }
  },
  created() {
    this.filterCats("");
    if (this.category) {
      this.myCategory = { ...this.category };
      if (this.category.parent_id != null) {
        this.myCategory.parent_id = this.category.parent_id;
      } else {
        this.myCategory.parent_id = 0;
      }
    }
    if (this.action === "read") {
      this.disableInfos = true;
      this.disableLangs = true;
    }
  },
  methods: {
    filterCats(search) {
      let mapped =
        this.action === "u"
          ? []
          : [
              {
                id: 0,
                label: this.$i18n.t("homepage"),
                itemClass: "pl-0 has-text-weight-bold",
                active: true,
                disabled: this.level == 1 || this.level == -1 ? false : true
              }
            ];
      for (let i = 0; i < this.categories.length; i++) {
        mapped.push({
          id: this.categories[i].id,
          label: this.categories[i].label,
          active: this.categories[i].active,
          itemClass: "pl-0 has-text-weight-bold",
          disabled: this.level == 2 || this.level == -1 ? false : true,
          level: 1
        });
        let firstChild = this.categories[i].children;
        if (firstChild.length > 0) {
          for (let j = 0; j < firstChild.length; j++) {
            mapped.push({
              id: firstChild[j].id,
              label: firstChild[j].label,
              active: firstChild[j].active,
              itemClass: "pl-4",
              disabled: this.level == 3 || this.level == -1 ? false : true,
              level: 2
            });
          }
        }
      }

      let filtered;

      if (this.category.id) {
        filtered = mapped.filter(option => {
          return option.id != this.category.id;
        });

        filtered = filtered.filter(option => {
          return (
            JSON.stringify(option.label)
              .toLowerCase()
              .indexOf(search.toLowerCase()) != -1
          );
        });
      } else {
        filtered = mapped.filter(option => {
          return (
            JSON.stringify(option.label)
              .toLowerCase()
              .indexOf(search.toLowerCase()) != -1
          );
        });
      }

      this.mappedCategories = filtered;
    },
    changeStep(step) {
      if (this.action != "update") {
        this.step = `${step}`;
      }
    },
    // beforeAddParent(row) {
    //   return !row.disabled;
    // },
    // changeParent() {
    //   this.myCategory.parent_id = [
    //     this.myCategory.parent_id[this.myCategory.parent_id.length - 1]
    //   ];
    // },
    saveGeneral() {
      if (!this.categoryId && this.$can('create', 'catalog.categories') ||
       (this.categoryId && this.$can('update', 'catalog.categories'))) {
        let finalCategory = {
          parent_id: this.myCategory.parent_id,
          google_category_id: this.myCategory.google_category_id,
          attribute: this.myCategory.attribute,
          noindex: this.myCategory.noindex,
          rank: this.myCategory.rank
        };

        this.save_general_loading = true;
        let category_id = this.myCategory.id ? "/" + this.myCategory.id : "";
        this.$axios({
          method: this.method,
          url: "categories" + category_id,
          data: finalCategory
        })
          .then(res => {
            if (!this.categoryId) {
              this.$root.notify(this.$t("successfully_saved"), "is-success");
            } else {
              this.$root.notify(this.$t("successfully_updated"), "is-success");
            }
            this.categoryId = res.data.id;
            this.myCategory.id = res.data.id;
            this.changeStep(2);
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.save_general_loading = false));
      }
    },
    getPermalinkValidation(site_id, lang_iso) {
      let required = site_id === 1 && lang_iso === "fr" ? "|required" : "";
      let valid_permalink = `valid_category_permalink:${site_id}/${lang_iso}/check${
        this.categoryId != null ? "/" + this.categoryId : ""
      }`;
      return this.action == "create" ? valid_permalink + required : required;
    },
    validSeoName(event, site_index, site_id, lang_index, lang_iso) {
      if (
        this.action === "create" ||
        !this.sites[site_index]["languages"][lang_index].permalink ||
        this.permalinkChangedFromInput.includes(`${site_index}_${lang_index}`)
      ) {
        let url = `categories/permalink/${encodeURIComponent(
          event.target.value.split("/").join(" ")
        )}/${site_id}/${lang_iso}/${
          this.categoryId != null ? this.categoryId : ""
        }`;
        this.$axios
          .get(url)
          .then(res => {
            this.$set(
              this.sites[site_index]["languages"][lang_index],
              "permalink",
              res.data.permalink
            );
            this.permalinkChangedFromInput.push(`${site_index}_${lang_index}`);
          })
          .catch(e => {
            let permalink =
              e.response && e.response.data && e.response.data.permalink;

            this.$set(
              this.sites[site_index]["languages"][lang_index],
              "permalink",
              permalink
            );
            const code = parseInt(e.response && e.response.status);
            if (code === 409) {
              let obj = {};
              obj["permalink" + site_id + lang_iso] = this.$i18n.t(
                "x_already_exists",
                {
                  field: this.sites[site_index]["languages"][lang_index][
                    "permalink"
                  ]
                }
              );
              this.$refs.observer.setErrors(obj);
            }
          });
      }
    },
    saveSeo() {
      if (this.action == "create" && this.$can('create', 'catalog.categories.seo') ||
       (this.action != "create" && this.$can('update', 'catalog.categories.seo'))) {
        this.save_seo_loading = true;

        let seo = [];
        let i;
        for (i = 0; i < this.sites.length; i++) {
          let j;
          for (j = 0; j < this.sites[i].languages.length; j++) {
            let languages = { ...this.sites[i]["languages"][j] };
            languages.lang_iso = this.sites[i]["languages"][j].iso;
            delete languages.iso;
            delete languages.label;
            seo.push(languages);
          }
        }
        this.$axios({
          method: "put",
          url: `categories/${this.categoryId}/trans`,
          data: seo
        })
          .then(() => {
            if (this.action == "create") {
              this.$root.notify(this.$t("successfully_saved"), "is-success");
            } else {
              this.$root.notify(this.$t("successfully_updated"), "is-success");
            }
            this.$router.push("/catalog/categories");
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.save_seo_loading = false));
      }
    }
  }
};
</script>

